import React from 'react';
import styles from './styles.module.scss';

const uspItems = [
  {
    title: 'Norsk merkevare',
    content: 'Vi er stolte av å gi deg trygge og markedsledende produkter utviklet i Norge.',
  },
  {
    title: 'Kvalitet',
    content: 'Vi velger utelukkende markedets beste ingredienser fordi vi vet du verdsetter høy produktkvalitet.',
  },
  {
    title: 'Alltid fornøyd',
    content: 'De aller fleste elsker produktene våre, men det er ikke alltid det fungerer for alle. Det vet vi, og det vet du. Ikke være redd for å prøve noe nytt. Du er nemlig dekket av vår 100% fornøydgaranti.',
  },
  {
    title: 'Raske pakker',
    content: 'Robotlager gjør at vi sender ordren din raskt og effektivt. Vi vet, akkurat som deg, hvor kjedelig det er å vente på pakker man gleder seg til.',
  },
];

function UspRail() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  function navigateToItem(index) {
    setSelectedItemIndex(index);
  }

  return (
    <div className={styles.container}>
      <div className={styles.railStyled}>
        <div className={styles.circleHighlighter} style={{ transform: `translateX(${selectedItemIndex * 85}px)` }} />
        <div className={styles.circleNavItemStyled} role="button" tabIndex="0" onClick={() => navigateToItem(0)} onKeyDown={() => navigateToItem(0)}>
          <img src="/icons/usp/norway.svg" alt="norway" style={selectedItemIndex === 0 ? { filter: 'brightness(0.6)', transform: 'scale(1.1)' } : {}} />
        </div>
        <div className={styles.circleNavItemStyled} role="button" tabIndex="0" onClick={() => navigateToItem(1)} onKeyDown={() => navigateToItem(1)}>
          <img src="/icons/usp/quality.svg" alt="quality" style={selectedItemIndex === 1 ? { filter: 'brightness(0.6)', transform: 'scale(1.1)' } : {}} />
        </div>
        <div className={styles.circleNavItemStyled} role="button" tabIndex="0" onClick={() => navigateToItem(2)} onKeyDown={() => navigateToItem(2)}>
          <img src="/icons/usp/happy.svg" alt="happy" style={selectedItemIndex === 2 ? { filter: 'brightness(0.6)', transform: 'scale(1.1)' } : {}} />
        </div>
        <div className={styles.circleNavItemStyled} role="button" tabIndex="0" onClick={() => navigateToItem(3)} onKeyDown={() => navigateToItem(3)}>
          <img src="/icons/usp/icon-package.svg" alt="fast package" style={selectedItemIndex === 3 ? { filter: 'brightness(0.6)', transform: 'scale(1.1)' } : {}} />
        </div>
      </div>

      <div className={styles.contentStyled}>
        <b>
          {uspItems[selectedItemIndex].title}
          :
          {' '}
        </b>
        <span>
          {`${uspItems[selectedItemIndex].content}`}
        </span>
      </div>

    </div>
  );
}

export default React.memo(UspRail);
