import Link from 'next/link';
import { Image } from '@crystallize/react-image';
import GridRenderer from '@crystallize/grid-renderer';
import Head from 'next/head';
import styles from './Grid.module.scss';

const ImageToPreload = ({ image }) => {
  const imageSrcSet = [];
  for (let i = 0; i < image.variants.length; i += 1) {
    const variant = image.variants[i];
    if (!variant.url.endsWith('avif')) {
      imageSrcSet.push(`${variant.url} ${variant.width}w`);
    }
  }
  return (
    <Head>
      <link
        rel="preload"
        as="image"
        href={image?.url}
        imageSizes="(max-width: 499px) 200px, 768px"
        imageSrcSet={imageSrcSet}
      />
    </Head>
  );
};

const children = ({ cells }) => cells.map((cell, key) => {
  const image = cell?.item?.components?.[1]?.content?.images?.[0] ?? null;
  const url = cell?.item?.components?.[2]?.content?.text ?? cell.item.path;
  return (
    <div
      key={cell?.item?.id}
      className={styles[`col-${cell.layout.colspan}-row-${cell.layout.rowspan}`]}
    >
      <Link prefetch={false} href={url}>
        <a>
          <div className={styles.cell}>
            <div className={styles.cellText}>
              {cell?.item?.components?.[0]?.content?.text
            && <p>{cell.item.components[0].content.text}</p>}
            </div>
            <div className={styles.imageWrapper}>
              {key <= 1 && image ? (
                <>
                  <Image
                    {...image}
                    sizes="(max-width: 499px) 200px, 768px"
                  />
                  <ImageToPreload image={image} />
                </>
              )
                : (
                  <Image
                    {...image}
                    imagesizes="(max-width: 499px) 200px, 768px"
                  />
                )}
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
});

export default function Grid({ grid }) {
  return (
    <div className={styles.gridWrapper}>
      <GridRenderer
        model={grid}
      >
        {children}
      </GridRenderer>
    </div>
  );
}
