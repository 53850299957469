import Layout from 'components/templates/layout/Layout';
import Grid from 'components/templates/grid';
import SiteRatings from '../../components/organisms/yotpo/site-ratings';
import styles from './Index.module.scss';
import UspRail from '../../components/molecules/usp/usp';

export default function Home({ data, footerDocs }) {
  return (
    <Layout title="Proteinpulver, kollagen, smart mat og kosttilskudd fra MyRevolution" footerDocs={footerDocs}>
      <div className={styles.topContainer}>
        <SiteRatings />
        <hr />
        <br />
        <UspRail />
      </div>

      <Grid {...data} />
    </Layout>
  );
}
