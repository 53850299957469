export default function SiteRatings() {
  return (
    <div style={{ minHeight: '300px' }}>
      <div
        className="yotpo yotpo-reviews-carousel"
        data-background-color="transparent"
        data-mode="most_recent"
        data-type="both"
        data-count="9"
        data-show-bottomline="1"
        data-autoplay-enabled="1"
        data-autoplay-speed="3000"
        data-show-navigation="1"
      >
      &nbsp;
      </div>
    </div>
  );
}
